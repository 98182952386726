a<template>
  <div class="main">
      <img src="/img/static/logo-landing.svg" />
      <h1>Privacy Policy</h1>
      <h4>We don’t collect or share any personal data.</h4>
      <h4>Last Updated: October 4th, 2022</h4>
      <br />
      <p>This is what SecurMeet has been built for:</p>
      <p>
          At SecurMeet, privacy is built into the core of our product and
          technology. We have a strict no logs and no history policy and do not
          collect any user data or communication content. All your messages, files,
          audio and video calls are encrypted using keys that only you have access
          to, and which we cannot replicate. In other words,
          <b>we do not and technically cannot view your data even if we wanted
              to.</b>
      </p>
      <p>
          When you enter a voice or video call with another user of SecurMeet, a
          Peer-to-Peer connection is established to handle the communication. This
          means that the audio and video data in that call are transferred directly
          from device to device without going through any middleman server, even
          ours. This means that it is technically impossible for us, or even for
          your own IT staff, to access the content of these communications. If there
          are too many people on a conference call for your device to handle it, the
          signal is automatically routed via your dedicated instance or local
          server, depending on your configuration. Either way,
          <b>the signal is encrypted end-to-end</b> to prevent any interception or
          snooping.
      </p>
      <p>
          We do not log, track or otherwise exploit any metadata or information
          about your communications. This means that we do not know or record how
          often you use the app, how many video calls or messages you send or to
          whom you send them.
      </p>
      <h3>Account and Profile Information.</h3>
      <p>
          The only identifiable information we collect about you is the username and
          email address you provide during sign up, as well as some minimal
          information needed for invoicing and accounting purposes. This information
          is used to create and maintain your account and subscription but will
          never be shared with third parties.
      </p>
      <h3>Analytics.</h3>
      <p>
          We use Google Analytics on the SecurMeet website as an analytics provider
          - but never in the SecurMeet app or Web app. We use it to measure our main
          site's performance and visitors but are unable to match this data with any
          user data to identify you individually. To learn more about the privacy
          policy of Google Analytics, refer to Google's Policies and Principles. Use
          the Google Analytics Opt-Out browser add-on to prevent to analytics
          information from being sent to Google Analytics.
      </p>
      <i>
          Note: there are no analytics plugins on the logged-in section of the
          website.
      </i>
      <h3>Cookies and Other Tracking Technologies.</h3>
      <p>
          We use cookies to improve and customize SecurMeet Services and your
          experience; it allows you to access and use websites or SaaS Products. You
          can however instruct your browser to stop accepting cookies or to prompt
          you before accepting a cookie from websites you visit. Your browser’s
          Options menu will have your cookie settings. If you do not accept cookies,
          however, you may not be able to use all aspects of our website and may
          need to log in several times during one session.
      </p>
      <p>
          If you decide to participate in a survey, contest, promotion, event, or if
          you request customer support, or communicate via third party social media
          sites or via our main website, some of your personal data may be collected
          or may be visible publicly, but we will never share this data with third
          parties.
      </p>
      <h3>General Uses.</h3>
      <p>
          We may use whatever little personal data you communicate to us (username,
          email and optionally a phone number) for a variety of purposes, including
          to:
      </p>
      <p>
      <ul>
          <li>
              Provide, operate, maintain, improve, and promote SecurMeet Services;
          </li>
          <li>
              Enable you to access and use SecurMeet Services;
          </li>
          <li>
              Process and complete
              transactions, and send you related information, including purchase
              confirmations and invoices;
          </li>
          <li>
              Communicate with you, including responding
              to your comments, questions, and requests; providing customer service and
              support; providing you with information about services, features, surveys,
              newsletters, offers, promotions, contests and events; providing other news
              or information about us and our select partners; and sending you technical
              notices, updates, security alerts, and support and administrative
              messages. Generally, you have the ability to opt out of receiving any
              promotional communications;
          </li>
          <li>
              Investigate and prevent fraudulent
              transactions, unauthorized access to SecurMeet Services, and other illegal
              activities;
          </li>
          <li>
              Enable you to communicate, collaborate, and share content
              with users you designate;
          </li>
          <li>
              For other purposes about which we will
              notify you.
          </li>
      </ul>


      </p>
      <p>
          Notwithstanding the foregoing, we will not use personal information
          appearing in our analytics logs or web logs for any purpose other than our
          internal maintenance and improvement, and will never share this data with
          any third party.
      </p>
      <h3>Compliance with Laws and Law Enforcement Requests.</h3>
      <p>
          Protection of Our Rights: In rare cases, we may disclose the little
          information we have at our disposal (including your Personal Information)
          to a third party if (a) we believe that disclosure is reasonably necessary
          to comply with any applicable law, regulation, legal process or
          governmental request, (b) to enforce our agreements, policies and terms of
          service, (c) to protect the security or integrity of SecurMeet products
          and services, (d) to protect SecurMeet, our customers or the public from
          harm or illegal activities, or (e) to respond to an emergency which we
          believe in the good faith requires us to disclose information to assist in
          preventing the death or serious bodily injury of any person. Note that
          this does not enable us to read or access the content of your
          communications, which we are not technically able to intercept.
      </p>
      <p>
          We may change this privacy policy from time to time. If we make any
          changes, we will notify you by revising the “Effective Starting” date at
          the top of this Privacy Policy and, in some cases, where appropriate we
          may provide you with additional notice (such as adding a statement to the
          login screen or sending you an email notification). We encourage you to
          review our Privacy Policy whenever you use SecurMeet Services to stay
          informed about our information practices and the ways you can help protect
          your privacy.
      </p>
      <h3>International users.</h3>
      <p>
          If you are visiting from the European Union or other regions with laws
          governing data collection and use, please note that should you provide any
          Personal Information, you are agreeing to the potential transfer of this
          Personal Information to Australia and the United States.
      </p>
      <h3>Our policy towards children.</h3>
      <p>
          SecurMeet Services are not directed to individuals under 13. We do not
          knowingly collect personal information from children under 13. If we
          become aware that a child under 13 has provided us with personal
          information, we will take steps to delete such information. If you become
          aware that a child has provided us with Personal Information, please
          contact our Support Team by email  <a href="mailto:support@securcom.me">support@securcom.me</a>
      </p>
      <p>
          If you disagree with any changes to this Privacy Policy, you will need to
          stop using SecurMeet Services and deactivate your account(s).
      </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {};
</script>

<style scoped>
a {
color: #409875;
}

p {
line-height: 1.4rem;
}

li {
margin-top: 5px;
}

h3 {
padding-top: 30px;
}

.main {
height: 91%;
margin-top: 20px;
overflow-y: scroll;
overflow-x: hidden;
}

.noB {
list-style: none;
margin-left: -20px;
}

img {
width: 100%;
max-width: 230px;
margin-left: -5px;
}
</style>